import axios from '../../../axios';
import Breadcrumb from '@/components/AppBreadcrumb.vue';

export default {
  components: {
    Breadcrumb
  },
  data() {
    return {
        data: null,
        documentID: null,
        document: null,
        documentSource: null,
        mimeType: null,
        showDetails: false,  
        errorMessage: '',
    };
  },
  created() {
    this.documentID = this.$route.params.documentID;
    this.getDocument();
  },
  methods: {
    async getDocument() {
      this.document = null;
      this.errorMessage = '';
      try {

        this.errorMessage = "loading...";
        const responseDocument = await axios.get(`/Documents/Details/${this.documentID}`);

        if( responseDocument.data.ERROR	 ){
            this.data = responseDocument.data;
            this.document = null;
            this.errorMessage = responseDocument.data.ERROR;

        }else if(responseDocument && responseDocument.data){
            this.data = responseDocument.data;
            this.document = responseDocument.data.Document;

            this.mimeType = 'application/pdf';
            this.documentSource = `data:${this.mimeType};base64,${responseDocument.data.Base64}`,
            this.errorMessage = "";
        } else {
            this.document = null;
            this.errorMessage = "No orders found";
        }

      } catch (error) {
        console.error("Error fetching orders:", error);
        this.document = null;
        this.errorMessage = "ERROR: Failed to fetch orders. Please try again later.";
      }
    },
  }
};