import axios from '../../../axios';
import Breadcrumb from '@/components/AppBreadcrumb.vue';

export default {
  components: {
    Breadcrumb
  },
  data() {
    return {
      form: {
        ORDERID: '',
        MODE:'OPEN',
        KEY:null,
        key_Order: null,
        SORT: 'DUC DESC',
        DATE: null,
        DATETO: null,
        f_date: null,
        f_dateto: null,
        sort_val:'DUC',
        sort_order:'DESC',
        page:1,
        maxperpage:100,
      },
      errorMessage: '',
      orders: [],
    };
  },
  created() {
    this.searchOrders();
    // this.KEY = localStorage.getItem('userid');
  },
  watch: {
    'form.MODE'() {
      this.searchOrders();
    },
    'form.sort_val'(newSort) {
      if (newSort) {
        this.updateSort();
      }
    },
    'form.sort_order'(newOrder) {
      if (newOrder) {
        this.updateSort();
      }
    },
  },
  methods: {

    async searchOrders() {
      this.errorMessage = '';
      this.orders = [];
      try {

        if (this.form.f_date){
          const [year, month, day] = this.form.f_date.split('-');
          this.form.DATE = `${day}.${month}.${year}`;
        }else{
          this.form.DATE = null;
        }

        if (this.form.f_dateto && this.form.f_date == null){
          this.errorMessage = 'Select a from date';
          this.orders = [];
          return false;
        }else if( this.form.f_dateto ){
          const [year, month, day] = this.form.f_dateto.split('-');
          this.form.DATETO = `${day}.${month}.${year}`;
        }else{
          this.form.DATETO = null;
        }

        // this.form.MODE = 'OPEN_COLLECTION';
        this.form.KEY = localStorage.getItem('key_client');

        this.errorMessage = 'loading....';
        const responseOrder = await axios.post('/Order/List', this.form);

        if(responseOrder && responseOrder.data.Orders){
            this.orders = responseOrder.data.Orders;
            this.errorMessage = '';
        } else {
            console.error('No orders found');
            this.errorMessage = 'No orders found';
        }

      } catch (error) {
        console.error("Error fetching orders:", error);
        this.orders = [];
        this.errorMessage = "ERROR:", error;
      }
    },
    updateSort() {
      this.form.SORT = `${this.form.sort_val} ${this.form.sort_order}`;
      this.searchOrders();
    }
  }
};