import axios from '../../../axios';
import Breadcrumb from '@/components/AppBreadcrumb.vue';

export default {
  components: {
    Breadcrumb
  },
  data() {
    return {
        orderID: null,
        orderData: null,
        order: null,
        items: [],
        address: null,
        showDetails: false,  
        errorMessage: '',
    };
  },
  created() {
    this.orderID = this.$route.params.orderId;
    this.getOrder();
  },
  methods: {
    async getOrder() {
      this.errorMessage = '';
      this.order = [];
      try {

        this.errorMessage = "loading...";
        const responseOrder = await axios.get(`/Order/Details/${this.orderID}`);

        if(responseOrder && responseOrder.data.Order){
            this.orderData = responseOrder.data;
            this.order = responseOrder.data.Order;
            this.items = responseOrder.data.Items;
            this.address = responseOrder.data.DeliveryAdress;
            this.errorMessage = "";
        } else {
            this.errorMessage = "No orders found";
        }

      } catch (error) {
        console.error("Error fetching orders:", error);
        this.order = [];
        this.errorMessage = "ERROR: Failed to fetch orders. Please try again later.";
      }
    },
    async modifyOrder(item){
      const dataOrder = {
        BID: this.order.BID,
        key_Item: item.key_Item,
        key_Prod: null,
        Amount: item.count,
      };

      const responseModifyOrder = await axios.post('/Basket/ModifyOrderItem', dataOrder);    
      console.log(responseModifyOrder);
      this.getOrder();
    },
    async sendBasket(){
      const dataBasket = {
        BID: this.order.BID
      };
      var endPoint = 'SendBasket';
      console.log(this.order.BASKET_STATUS);
      if(this.order.BASKET_STATUS == 'OPEN_COLLECTION'){
        endPoint = 'SendCollection';
      }
      const responseSendBasket = await axios.post('/Basket/'+endPoint, dataBasket);
      localStorage.removeItem('BID');
      console.log(responseSendBasket);
    },
    async deleteBasket(){
      const dataBasket = {
        BID: this.order.BID
      };
      const responseDeleteBasket = await axios.post('/Basket/DeleteBasketPhysically', dataBasket);
      localStorage.removeItem('BID');
      console.log(responseDeleteBasket);
      this.$router.push('/orders');
    }
  }
};