import axios from '../../../axios';
import Breadcrumb from '@/components/AppBreadcrumb.vue';

export default {
  components: {
    Breadcrumb
  },
  data() {
    return {
      form: {
        MODE: 'FREETEXT',  // default value
        CRIT: '',   // user input
        MANUF: '',
        CLIENT: '',
        state_active: 1,
        state_outofbusiness: null,
        state_BTM: null,
        state_fridge: null,
        state_SLlist: null,
        maxperpage: 100,
        SORT: 'ProdName ASC',
        sort_val:'ProdName',
        sort_order:'ASC',
      },
      searchInitiated: false,
      errorMessage: '',
      products: [],
      manufacturer: [],
      client_name: false,
      key_client: false,
      selected_client: false,
      clientlist: [],
      imageValid: true, 
      currentImage: '',
      fallbackImage: require('@/assets/images/product-placeholder.svg')
    };
  },
  created() {
    const storedClient = localStorage.getItem("selected_client");
    if( storedClient ){
      this.selected_client = storedClient;
    }else{
      this.selected_client = localStorage.getItem('key_client');
    }
    this.getClientList();
    this.setBID();
    // this.searchProducts();
  },
  watch: {
    'form.MANUF'() {
      this.searchProducts();
    },
    'form.sort_val'(newSort) {
      if (newSort) {
        this.updateSort();
      }
    },
    'form.sort_order'(newOrder) {
      if (newOrder) {
        this.updateSort();
      }
    },
    'form.state_active'() {
      this.searchProducts();
    },
    'form.state_outofbusiness'() {
      this.searchProducts();
    },
    'form.state_BTM'() {
      this.searchProducts();
    },
    'form.state_fridge'() {
      this.searchProducts();
    },
    'form.state_SLlist'() {
      this.searchProducts();
    },

  },
  methods: {    
    async getClientList(){
      this.client_name = localStorage.getItem('client_name');
      this.key_client = localStorage.getItem('key_client');
      
      const responseSubclient = await axios.get(`SubClient/ListForClient/${this.key_client}`);
      if(responseSubclient){
        this.clientlist = responseSubclient.data.SubClients;
      }else{
        console.log('Invalid client list');
      }
    },
    async onClientChange(){
      localStorage.setItem('selected_client',this.selected_client)
      this.setBID();
    },
    async setBID(){
      const basket_id = localStorage.getItem('bid_' + this.selected_client);
      if( basket_id){
        localStorage.setItem('BID', basket_id	);
        console.log('Switch basket ID:', basket_id);
      }else{
        const dataOrders = {
          "MODE":"OPEN",
          "KEY":this.key_client,
          "DATE":null,
          "DATETO":null,
          "SORT":"DUC DESC",
          "page":1,
          "maxperpage":20
        
        };

        // Check if existing order to get the BID from the Order
        const responseOrder = await axios.post('/Order/List', dataOrders);
        if(responseOrder.data.Orders){
          responseOrder.data.Orders.forEach(order => {
            if( order.key_ClientSub && order.key_ClientSub == this.selected_client){
                localStorage.setItem('BID', order.BID	);
                localStorage.setItem('bid_' + this.selected_client, order.BID	);
                console.log('Set basket from list ClientSub basket ID:', order.BID	);
            }else if(!order.key_ClientSub && order.key_Client == this.selected_client){
              localStorage.setItem('BID', order.BID	);
              localStorage.setItem('bid_' + this.selected_client, order.BID	);
              console.log('Set basket from list Client basket ID:', order.BID	);
            }
          });
        }

        if(!localStorage.getItem('bid_' + this.selected_client)){
          // Create new basket ID
          const dataKey = {
            key_Client: this.key_client,
            key_Person: null,
            key_Inventory: null,
            Origin: "SERVICE",
            ExternalOrderID: "XP123123"
          };

          if( this.selected_client !=  this.key_client){
            dataKey['key_SubClient'] = this.selected_client
          }

          const response = await axios.post('Basket/Create', dataKey);       
          let responseData = response.data;
          if( responseData && responseData.BID){
            localStorage.setItem('bid_' + this.selected_client, responseData.BID	);
            localStorage.setItem('BID', responseData.BID	);
            console.log('Create new basket ID:', responseData.BID);
          }else{
            localStorage.removeItem('BID');
            console.log('Invalid basket ID:', responseData.BID);
          }
        }
      }
    },
    async addToCart(product) {
      // const existingProduct = this.cart.find(item => item.id === product.id);
      console.log(product.ProdName);
      const basket_id = localStorage.getItem('BID');
      if( !basket_id ){
        this.setBID();
      }

      const basketData = {
        "BID":localStorage.getItem('BID'),
        "MODE":"CREATE",
        "Articles":
        [
          {
            "EAN":product.EAN,
            "Pharmacode":product.PharmaCode,
            "Amount":Number(product.amount)
          }
        ]
      }

      const basketResponse = await axios.post('Basket/AddBasket', basketData); 
      if(basketResponse.data.Success){
        alert( product.ProdName + ' wurde in den warenkorb gelegt' );
      }else{
        alert( 'ERROR: ' + product.ProdName + ' konnte NICHT in den warenkorb gelegt werden' );
      }

    },
    async searchProducts() {
      this.errorMessage = '';
      this.products = [];

      if (!this.form.CRIT || this.form.CRIT.trim() === '') {
        this.errorMessage = 'Bitte geben sie einen gültigen Suchbegriff ein.';
        this.searchInitiated = false; 
        return false;
      }

      try {

        this.errorMessage = 'loading....';

        const response = await axios.post('/Article/Search', this.form);       
        let responseData = response.data;

        // Add `showDetails` to each product after fetching
        if(responseData.Products){
            this.products = responseData.Products.map(product => {
                return { ...product, showDetails: false }; // Add showDetails property
            });
            this.searchInitiated = true;
            this.errorMessage = '';
        }else{
            this.products = [];
            this.errorMessage = 'Keine Produkte gefunden';
        }

        if(responseData.Hersteller){
            this.manufacturer = responseData.Hersteller;
        }else{
            this.manufacturer = [];
        }

      } 
      catch (error) {
        console.error("Error fetching products:", error);
        this.products = [];
        this.errorMessage = "Failed to fetch products. Please try again later.";
      }
    },
    updateSort() {
      this.form.SORT = `${this.form.sort_val} ${this.form.sort_order}`;
      this.form.FILTER
      this.searchProducts();
    },
    getProductImage(imageUrl) {
      return imageUrl || this.fallbackImage;
    },
    checkImageSize(index) {
      const img = this.$refs['productImage-' + index][0];  // Access the correct image ref
      if (img) {
        const width = img.naturalWidth;
        const height = img.naturalHeight;

        // Check if the image is smaller than 2px
        if (width < 2 || height < 2) {
          this.products[index].ImageFront = this.fallbackImage;
        }
      }
    }
  },
  
};