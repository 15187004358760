import Vue from 'vue';
import App from './App.vue';
import router from './router';
import './assets/sass/main.scss';
import { formatDate } from './utils/formatUtils';
import { formatDateTime } from './utils/formatUtils';
import { breadcrumbMixin } from './mixins/breadcrumbMixin'; 

Vue.config.productionTip = false;


Vue.prototype.$formatDate = formatDate;
Vue.prototype.$formatDateTime = formatDateTime;
Vue.mixin(breadcrumbMixin);

new Vue({
  router,  
  data() {
    return {
      loading: false
    };
  },
  methods: {
    setLoading(isLoading) {
      this.loading = isLoading;
    }
  },
  render: h => h(App)
}).$mount('#app');
